// import React, {Component} from "react";
// import Api from "../../../libraries/api";
// import TextField from "@material-ui/core/TextField";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import IconButton from "@material-ui/core/IconButton";
// import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
// import MomentUtils from "@date-io/moment";
// import Table from "@material-ui/core/Table";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import TableCell from "@material-ui/core/TableCell";
// import TableBody from "@material-ui/core/TableBody";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import TablePagination from "@material-ui/core/TablePagination";
// import TablePaginationActions from "../../presentational/table-pagination-actions";
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
// import {Link} from "react-router-dom";
// import DateFormat from "../../../libraries/data-format-helper";

// export default class SalesDeliveredLists extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             loading: true,
//             rows: [],
//             sendPageNumber: '',
//             sendPerPage: '',
//             perPage: 50,
//             currentPage: 0,
//             currentPageTable: 0,
//             totalData: 0,
//             showDetail: false,
//             search: '',
//             purchaseData: [],
//         };
//         this.getPurchaseDelivered();
//         this.handlePurchaseDelivered = this.handlePurchaseDelivered.bind(this);
//         this.handleClose = this.handleClose.bind(this);
//     }

//     handleChange(e, prop) {
//         this.setState({
//             [prop]: e.target.value
//         })
//     };

//     getPurchaseDelivered() {
//         let currentPage = this.state.currentPage + 1;
//         let url = "/purchase/type/delivered?perPage=" + this.state.perPage + "&pageNumber=" + currentPage + "&search=" + this.state.search;
//         // Api.get(url, [], false).then((resp) => {
//         //     this.setState({
//         //         rows: resp["data"]["Purchases"],
//         //         loading: false,
//         //         totalData: resp["data"]["meta"]["totalData"]
//         //     });
//         // });
//     }

//     handleClose() {
//         this.setState({showDetail: false})
//     }

//     handlePurchaseDelivered(purchaseId) {
//         let url = "/purchase/detail/" + purchaseId;
//         Api.get(url, [], false).then((resp) => {
//             this.setState({
//                 showDetail: true,
//                 purchaseData: resp["data"]["Purchase"]
//             })
//         });

//     }

//     handleKeyPressSearch = (event) => {
//         if (event.key === 'Enter') {
//             this.setState({
//                 rows: [],
//                 currentPage: 0,
//                 currentPageTable: 0,
//                 loading: true,
//             }, () => {
//                 this.getPurchaseDelivered();
//             });
//         }
//     };

//     handleChangePage = (event, newPage) => {
//         this.setState({
//             rows: [],
//             currentPage: newPage,
//             currentPageTable: newPage,
//             loading: true,
//         }, () => {
//             this.getPurchaseDelivered();
//         });
//     };

//     handleChangeRowsPerPage = event => {
//         this.setState({
//             perPage: event.target.value,
//             currentPage: 0,
//             currentPageTable: 0,
//             loading: true,
//         }, () => {
//             this.getPurchaseDelivered()
//         });
//     };

//     render() {
//         return (
//             <div className="row">
//                 <div className="col-12 card">
//                     <div className="row align-items-center">
//                         <div className="col-md-4 search-bar">
//                             <TextField
//                                 type="text"
//                                 id={"adornment-search-delivered"}
//                                 name="search"
//                                 placeholder="ordered"
//                                 onChange={(e) => this.handleChange(e, 'search')}
//                                 onKeyPress={this.handleKeyPressSearch}
//                                 value={this.state.search}
//                                 variant="outlined"
//                                 InputProps={{
//                                     startAdornment: (
//                                         <InputAdornment position="start">
//                                             <IconButton
//                                                 aria-label="Search button"
//                                                 onClick={this.handleClickSearch}
//                                             >
//                                                 <i className="fas fa-search"> </i>
//                                             </IconButton>
//                                         </InputAdornment>
//                                     )
//                                 }}
//                                 fullWidth
//                             />
//                         </div>
//                         <div className="col-md-2 filter">
//                             <MuiPickersUtilsProvider utils={MomentUtils}>
//                                 <KeyboardDatePicker
//                                     variant="inline"
//                                     margin="normal"
//                                     id={"start_date_delivered"}
//                                     label="Date"
//                                     format={"MM/DD/YYYY"}
//                                     value={this.state.start_date}
//                                     onChange={(date) => this.handleChangeSearch(date, 'start_date')}
//                                     KeyboardButtonProps={{
//                                         'aria-label': 'change date',
//                                     }}
//                                 />
//                             </MuiPickersUtilsProvider>
//                         </div>
//                     </div>
//                     <PurchaseDeliveredDetail showDetail={this.state.showDetail}
//                                              handleClose={this.handleClose}
//                                              purchaseData={this.state.purchaseData}/>
//                     <div className="table-wrapper">
//                         <Table className="table-list mt-3" size="small">
//                             <TableHead>
//                                 <TableRow>
//                                     <TableCell>Order</TableCell>
//                                     <TableCell>Request Date</TableCell>
//                                     <TableCell>Status</TableCell>
//                                     <TableCell>Delivered Date</TableCell>
//                                     <TableCell>Contact</TableCell>
//                                     <TableCell>Zip Code</TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             {this.state.loading ?
//                                 (
//                                     <TableBody>
//                                         <TableRow>
//                                             <TableCell colSpan={8} align="center" className="py-5">
//                                                 <CircularProgress color="primary"/>
//                                             </TableCell>
//                                         </TableRow>
//                                     </TableBody>
//                                 ) :
//                                 (
//                                     <PurchaseDeliveredTable rows={this.state.rows}
//                                                             handlePurchaseDelivered={this.handlePurchaseDelivered}/>
//                                 )
//                             }

//                         </Table>
//                     </div>
//                     <div>
//                         <TablePagination
//                             rowsPerPageOptions={[50, 75, 100]}
//                             component="div"
//                             count={this.state.totalData}
//                             rowsPerPage={this.state.perPage}
//                             page={this.state.currentPageTable}
//                             backIconButtonProps={{
//                                 'aria-label': 'previous page',
//                             }}
//                             nextIconButtonProps={{
//                                 'aria-label': 'next page',
//                             }}
//                             onChangePage={this.handleChangePage}
//                             onChangeRowsPerPage={this.handleChangeRowsPerPage}
//                             ActionsComponent={TablePaginationActions}
//                         />
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }

// class PurchaseDeliveredTable extends Component {

//     render() {
//         let handlePurchaseDelivered = this.props.handlePurchaseDelivered;
//         return (
//             <TableBody>
//                 {this.props.rows.map(function (item, i) {
//                     return (
//                         <TableRow key={i} onClick={() => handlePurchaseDelivered(item.id)}>
//                             <TableCell>{item.id}</TableCell>
//                             <TableCell>{DateFormat.dateTime(item.created)}</TableCell>
//                             <TableCell>{item.status}</TableCell>
//                             <TableCell>{DateFormat.dateTime(item.updatedAt)}</TableCell>
//                             <TableCell>{item.userName}</TableCell>
//                             <TableCell>{item.postalCode}</TableCell>
//                         </TableRow>
//                     )
//                 })}
//             </TableBody>
//         )
//     }
// }

// class PurchaseDeliveredDetail extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             deliveryProvider: [],
//             deliveryProviderId: '',
//             trackId: ''
//         }
//     }

//     componentDidMount() {
//         /*Delivery Catalog*/
//         Api.get('/delivery/provider', true).then(resp => {
//             if (resp.data) {
//                 this.setState({
//                     deliveryProvider: resp.data.deliveryProvider,
//                     deliveryProviderId: resp.data.deliveryProviderDefaultId
//                 });
//             }
//         })
//     }

//     handleChange(e, prop) {
//         this.setState({
//             [prop]: e.target.value
//         })
//     };

//     render() {
//         let handleClose = this.props.handleClose;
//         let purchase = this.props.purchaseData;
//         return (
//             <Dialog
//                 maxWidth={'md'}
//                 open={this.props.showDetail}
//                 onClose={handleClose}
//                 aria-labelledby="form-dialog-title"
//                 className="dialog-custom">
//                 <DialogTitle id="form-dialog-title">
//                     <div className="row align-items-center">
//                         <div className="col-md-6">
//                             <span>Order</span>
//                         </div>
//                         <div className="col-md-6">
//                             <p className="mb-0"><label className="title d-inline">Status</label>
//                                 <i className="status text-capitalize"> Delivered</i>
//                             </p>
//                         </div>
//                     </div>
//                     <button onClick={handleClose} className="btn-close">
//                         <i className="fas fa-times"></i>
//                     </button>
//                 </DialogTitle>
//                 <DialogContent>
//                     <div>
//                         <div className="row">
//                             <div className="col-md-6">
//                                 <label className="title">Account number</label>
//                                 <p className="pl-2 pl-lg-3">{purchase["userId"]}</p>
//                                 <Link to={'/accounts/detail/' + purchase["userId"]} className="btn btn-blue-trans mb-3">Go
//                                     to Account</Link>
//                                 <label className="title">Contact and Shipping</label>
//                                 <p className="pl-2 pl-lg-3">
//                                     {purchase["fullName"]}<br/>
//                                     {purchase["address"]}<br/>
//                                     {purchase["city"]}, {purchase["stateId"]} {purchase["postalCode"]}<br/>
//                                     {purchase["email"]}<br/>
//                                 </p>
//                             </div>
//                             <div className="col-md-6">
//                                 <div className="row">
//                                     <div className="col-md-6">
//                                         <label className="title">Request Date</label>
//                                         <p className="pl-2 pl-lg-3">{DateFormat.dateTime(purchase["created"])}</p>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <label className="title">Delivered Date</label>
//                                         <p className="pl-2 pl-lg-3">{DateFormat.dateTime(purchase["updatedAt"])}</p>
//                                     </div>
//                                 </div>
//                                 <div className="row">
//                                     <div className="col-md-6">
//                                         <label className="title">Delivery Provider</label>
//                                         <p className="pl-2 pl-lg-3">{purchase["provider"]}</p>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <label className="title">Tracking Id</label>
//                                         <p className="pl-2 pl-lg-3">{purchase["trackId"]}</p>
//                                     </div>
//                                     <div className="col-12">
//                                         <label className="title"></label>
//                                         <p className="pl-2 pl-lg-3"></p>
//                                     </div>
//                                 </div>
//                                 <div className="row">
//                                     <div className="col-md-6">
//                                         <label className="title">Toll Tag Id</label>
//                                         <p className="pl-2 pl-lg-3">{purchase["transponderShippedId"]}</p>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <label className="title">Toll Tag Fee</label>
//                                         <p className="pl-2 pl-lg-3">$0.0</p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </DialogContent>
//             </Dialog>
//         )
//     }
// }
import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import moment from "moment";
import DateFormat from "../../../libraries/data-format-helper";
import Tooltip from "@material-ui/core/Tooltip";  // Tooltip for error
import ErrorIcon from "@material-ui/icons/Error";  // Error icon for date issue
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class SalesOrderedLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      rows: [],
      perPage: 50,
      currentPage: 0,
      totalData: 0,
      search: "",
      sortBy: "createdAt", // Default sorting field
      sortOrder: "desc", // Default sorting order
      start_date: null, // Date filter
      end_date: null, // End date filter
      dateError: false,  // Error tracking for invalid date ranges
    };
    this.getPurchaseOrdered();
  }

  handleChange = (e, prop) => {
    this.setState({ [prop]: e.target.value });
  };

  // Handle date changes
  handleDateChange = (date) => {
    this.setState({ start_date: date }, () => {
      this.getPurchaseOrdered(); // Call API after date change
    });
  };

  handleEndDateChange = (date) => {
    if (this.state.start_date && moment(date).isBefore(this.state.start_date)) {
      this.setState({ dateError: true });
    } else {
      this.setState({ end_date: date, dateError: false }, () => {
        this.getPurchaseOrdered();
      });
    }
  };

  // Fetch the purchase orders with pagination, search, sorting, and date filtering
  getPurchaseOrdered = () => {
    const { perPage, currentPage, search, sortBy, sortOrder, start_date, end_date } = this.state;
    const token = localStorage.getItem('access_token');

    let url = `https://stg.api.neoride.com/api/parking/v1/orders/status/delivered?limit=${perPage}&page=${currentPage + 1}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`;

    // Append start and end date if provided
    if (start_date) {
      url += `&start_date=${moment(start_date).format("YYYY-MM-DD")}`;
    }
    if (end_date) {
      url += `&end_date=${moment(end_date).format("YYYY-MM-DD")}`;
    }

    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    })
      .then(response => response.json())
      .then(resp => {
        if (resp) {
          this.setState({
            rows: resp.orders || [],  // Ensure rows is always an array
            totalData: resp.total || 0,
            loading: false,
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          rows: [], // Set to empty array on error
          totalData: 0,
          loading: false,
        });
      });
  };

  // Handle search when Enter is pressed or when the search button is clicked
  handleKeyPressSearch = (event) => {
    if (event.key === "Enter") {
      this.setState({ rows: [], currentPage: 0, loading: true }, () => {
        this.getPurchaseOrdered();
      });
    }
  };

  handleSearchClick = () => {
    this.setState({ rows: [], currentPage: 0, loading: true }, () => {
      this.getPurchaseOrdered();
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ rows: [], currentPage: newPage, loading: true }, () => {
      this.getPurchaseOrdered();
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ perPage: event.target.value, currentPage: 0, loading: true }, () => {
      this.getPurchaseOrdered();
    });
  };

  // Handle when an order row is clicked
  handlePurchaseOrdered = (orderId) => {
    console.log(`Order ID clicked: ${orderId}`);
    // Add your logic here for handling the click, e.g., redirecting to order details page
  };

  // Handle sorting functionality
  handleSort = (field) => {
    if (field === 'createdAt') {
      const { sortBy, sortOrder } = this.state;
      let newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'; // Toggle sorting order
      this.setState({ sortBy: field, sortOrder: newSortOrder, loading: true }, () => {
        this.getPurchaseOrdered();
      });
    }
  };

  renderSortArrow = (field) => {
    const { sortBy, sortOrder } = this.state;
    if (sortBy === field) {
      return sortOrder === "asc" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
    }
    return null; // No arrow if the field is not sorted
  };

  render() {
    return (
      <div className="row">
        <div className="col-12 card">
          <div className="row align-items-center">
            <div className="col-md-4 search-bar">
              <TextField
                type="text"
                id="adornment-search-ordered"
                name="search"
                placeholder="Search Orders"
                onChange={(e) => this.handleChange(e, "search")}
                onKeyPress={this.handleKeyPressSearch}
                value={this.state.search}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton aria-label="Search button" onClick={this.handleSearchClick}>
                        <i className="fas fa-search"> </i>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </div>

            {/* Start Date Picker */}
            <div className="col-md-2 filter">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  margin="normal"
                  id="start_date_ordered"
                  label="Start Date"
                  format="MM/DD/YYYY"
                  value={this.state.start_date}
                  onChange={this.handleDateChange}
                  KeyboardButtonProps={{ "aria-label": "change start date" }}
                />
              </MuiPickersUtilsProvider>
            </div>

            {/* End Date Picker */}
            <div className="col-md-2 filter" style={{ display: 'flex', alignItems: 'center' }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  margin="normal"
                  id="end_date_ordered"
                  label="End Date"
                  format="MM/DD/YYYY"
                  value={this.state.end_date}
                  onChange={this.handleEndDateChange}
                  KeyboardButtonProps={{ "aria-label": "change end date" }}
                  // Apply red border if there's an error
                  style={{
                    borderColor: this.state.dateError ? 'red' : 'initial',
                    borderWidth: this.state.dateError ? '2px' : '1px',
                    borderStyle: this.state.dateError ? 'solid' : 'none',
                  }}
                />
              </MuiPickersUtilsProvider>

              {/* Display tooltip with error icon if there's an error */}
              {this.state.dateError && (
                <Tooltip title="End date cannot be earlier than start date" placement="right">
                  <ErrorIcon style={{ color: 'red', marginLeft: '5px' }} />
                </Tooltip>
              )}
            </div>
          </div>

          <div className="table-wrapper">
            <Table className="table-list mt-3" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Transponder Id</TableCell>
                  <TableCell>Transponder Name</TableCell>
                  <TableCell>User Id</TableCell>
                  <TableCell onClick={() => this.handleSort('createdAt')}>
                    Delivery Date {this.renderSortArrow('createdAt')}
                  </TableCell>
                  
                  <TableCell>LicensePlate No</TableCell>
                  <TableCell>LicensePlate St</TableCell>
                  
                </TableRow>
              </TableHead>

              {this.state.loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8} align="center" className="py-5">
                      <CircularProgress color="primary" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <PurchaseOrderedTable
                  rows={this.state.rows}
                  handlePurchaseOrdered={this.handlePurchaseOrdered}  // Pass the handler
                />
              )}
            </Table>
          </div>

          {/* Pagination */}
          <div>
            <TablePagination
              rowsPerPageOptions={[50, 75, 100]}
              component="div"
              count={this.state.totalData}
              rowsPerPage={this.state.perPage}
              page={this.state.currentPage}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onPageChange={this.handleChangePage}  // Use the new onPageChange prop
              onRowsPerPageChange={this.handleChangeRowsPerPage}  // Use the new onRowsPerPageChange prop
              ActionsComponent={TablePaginationActions}
            />
          </div>

          {/* Toast Container */}
          <ToastContainer />
        </div>
      </div>
    );
  }
}

class PurchaseOrderedTable extends Component {
    render() {
      const { rows, handlePurchaseOrdered } = this.props;  // Accept the handler as a prop
  
      if (!rows || rows.length === 0) {
        return (
          <TableBody>
            <TableRow>
              <TableCell colSpan={6} align="center">
                No records found
              </TableCell>
            </TableRow>
          </TableBody>
        );
      }
  
      return (
        <TableBody>
          {rows.map((item, i) => (
            <TableRow key={i} onClick={() => handlePurchaseOrdered(item.transponderId)}>
              <TableCell>{item.transponderId}</TableCell>
              <TableCell>{item.tagName}</TableCell>
              <TableCell>{item.userId}</TableCell>
              <TableCell>{DateFormat.dateTime(item.createdAt)}</TableCell>
              <TableCell>{item.licensePlateNumber}</TableCell>
              <TableCell>{item.licensePlateState}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      );
    }
  }
  
