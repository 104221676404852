import React, { Component } from 'react';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import TablePaginationActions from '../../presentational/table-pagination-actions';
import moment from "moment";
import Api from '../../../libraries/api';
import { history } from '../../../shared/configure-store';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//src/components/container/transactions/transponders-log.js
export default class TranspondersLog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      loading: true,
      total: 0,
      perPage: 50,
      currentPage: 0,
      currentPageTable: 0,
      totalData: 0,
      sortField: 'createdAt',
      sortDir: 'DESC',
      search: '',
      start_date: null,
      end_date: null,
      dateError: false,
    };
    this.getTranspondersLogList();
  }

getTranspondersLogList = async (update = false) => {
    try {
        let page = update ? this.state.currentPage : this.state.currentPage + 1; // Handle zero-based index here
      const { perPage, sortField, sortDir, search, start_date, end_date } = this.state;
      let url = `/user-tag-vehicle-details?limit=${perPage}&page=${page}&sortBy=${sortField}&sortOrder=${sortDir}`;
  
      if (search) url += `&search=${search}`;
      if (start_date) url += `&startDate=${moment(start_date).format('YYYY-MM-DD')}`;
      if (end_date) url += `&endDate=${moment(end_date).format('YYYY-MM-DD')}`;
  
      const resp = await Api.get(url, true, "parking");
  
      if (resp.data && Array.isArray(resp.data)) {
        this.setState({
          loading: false,
          rows: resp.data, 
          total: resp.total,
          perPage: resp.limit,
          currentPage: resp.page,
          currentPageTable: resp.page - 1,
          totalData: resp.total,
        });
      } else {
        console.error("Unexpected API response structure");
        this.setState({ loading: false, rows: [] });
        toast.error("Unexpected response from the server.");
      }
    } catch (err) {
      this.setState({ loading: false });
      toast.error("Failed to fetch transponder logs.");
    }
  };
  
  handleChangePage = (event, newPage) => {
    this.setState({
      rows: [],
      currentPage: newPage,
      currentPageTable: newPage,
      loading: true,
    }, () => {
      this.getTranspondersLogList(true);
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      perPage: event.target.value,
      currentPage: 0,
      currentPageTable: 0,
      loading: true,
    }, () => {
      this.getTranspondersLogList(false);
    });
  };

  handleSort = (field) => {
    const isAsc = this.state.sortField === field && this.state.sortDir === "DESC";
    const sortDir = isAsc ? "ASC" : "DESC";
    this.setState({
      sortField: field,
      sortDir,
      currentPage: 0,
      currentPageTable: 0,
      loading: true,
    }, () => {
      this.getTranspondersLogList(false);
    });
  };

  handleSearchChange = (event) => {
    this.setState({ search: event.target.value });
  };

  handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.setState({ rows: [], currentPage: 0, loading: true }, () => {
        this.getTranspondersLogList(false);
      });
    }
  };

  handleDateChange = (date) => {
    this.setState({ start_date: date }, () => {
      this.getTranspondersLogList(false);
    });
  };

  handleEndDateChange = (date) => {
    if (this.state.start_date && moment(date).isBefore(this.state.start_date)) {
      this.setState({ dateError: true });
    } else {
      this.setState({ end_date: date, dateError: false }, () => {
        this.getTranspondersLogList(false);
      });
    }
  };

  render() {
    const { rows = [], loading } = this.state; // Ensure rows is always an array
  
    return (
      <div>
        <h2 className='text-title-big'>Transponders Details</h2>
        <div className='card' style={{ marginTop: '20px' }}>
  
          <div className="row">
            <div className="col-md-4">
              <TextField
                label="Search"
                variant="outlined"
                fullWidth
                value={this.state.search}
                onChange={this.handleSearchChange}
                onKeyPress={this.handleSearchKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <i className="fas fa-search"></i>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
  
            <div className="col-md-2">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="start-date"
                  label="Start Date"
                  format="MM/DD/YYYY"
                  value={this.state.start_date}
                  onChange={this.handleDateChange}
                />
              </MuiPickersUtilsProvider>
            </div>
  
            <div className="col-md-2">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="end-date"
                  label="End Date"
                  format="MM/DD/YYYY"
                  value={this.state.end_date}
                  onChange={this.handleEndDateChange}
                  style={{
                    borderColor: this.state.dateError ? 'red' : 'initial',
                    borderWidth: this.state.dateError ? '2px' : '1px',
                    borderStyle: this.state.dateError ? 'solid' : 'none',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
  
          <div className="table-wrapper">
            <Table className="table-list mt-3" size="small">
              <TableHead>
                <TableRow>
                  <TableCell onClick={() => this.handleSort('fullName')}>User Name</TableCell>
                  <TableCell onClick={() => this.handleSort('userId')}>User Id</TableCell>
                  <TableCell>Tag Id</TableCell>
                  <TableCell>Transponder Name</TableCell>
                  <TableCell onClick={() => this.handleSort('licensePlateNumber')}>License Plate</TableCell>
                  <TableCell onClick={() => this.handleSort('licensePlateState')}>Plate State</TableCell>
                  <TableCell onClick={() => this.handleSort('createdAt')}>Created At</TableCell>
                  <TableCell>Deleted At</TableCell>
                </TableRow>
              </TableHead>
  
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <CircularProgress color="primary" />
                    </TableCell>
                  </TableRow>
                ) : (
                  Array.isArray(rows) && rows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={8} align="center">No transponder Details Found</TableCell>
                    </TableRow>
                  ) : (
                    rows.map((row, index) => (
                      <TableRow key={index} onClick={() => history.push('/accounts/detail/' + row.userId)}>
                        <TableCell>{row.fullName || 'N/A'}</TableCell>
                        <TableCell>{row.userId}</TableCell>
                        <TableCell>{row.tagId}</TableCell>
                        <TableCell>{row.transponderName}</TableCell>
                        <TableCell>{row.licensePlateNumber}</TableCell>
                        <TableCell>{row.licensePlateState}</TableCell>
                        <TableCell>{row.createdAt ? moment(row.createdAt).format("MM/DD/YYYY, hh:mm:ss A") : "N/A"}</TableCell>
                        <TableCell>{row.deletedAt ? moment(row.deletedAt).format("MM/DD/YYYY, hh:mm:ss A") : "N/A"}</TableCell>
                      </TableRow>
                    ))
                  )
                )}
              </TableBody>
            </Table>
          </div>
  
          <TablePagination
            rowsPerPageOptions={[50, 75, 100]}
            component="div"
            count={this.state.totalData}
            rowsPerPage={this.state.perPage}
            page={this.state.currentPageTable}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </div>
        <ToastContainer />
      </div>
    );
  }
  
}
